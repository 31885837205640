<template>
<div class="piano-bigwrap">
  <div class="piano-container">
    <div class="piano">
      <button @click="plays(item.url)" v-for="item in listOne" :key="item.id"></button>
    </div>
    <div class="piano">
      <button @click="plays(item.url)" v-for="item in listTwo" :key="item.id"></button>
    </div>
    <div class="piano">
      <button @click="plays(item.url)" v-for="item in listThree" :key="item.id"></button>
    </div>
  </div>
  <audio id="audioElement" ref="playAudio" :src="playingAudio" autoplay></audio>
</div>
</template>
<script>
export default {
  name: 'piano',
  data () {
    return {
      arr: [
        {
          url: 'C4',
          key: 65
        }, {
          url: 'Db4',
          key: 87
        }, {
          url: 'D4',
          key: 83
        }, {
          url: 'Eb4',
          key: 69
        }, {
          url: 'E4',
          key: 68
        },
        {
          url: 'F4',
          key: 70
        }, {
          url: 'Gb4',
          key: 84
        }, {
          url: 'G4',
          key: 71
        }, {
          url: 'Ab4',
          key: 89
        }, {
          url: 'A4',
          key: 72
        }, {
          url: 'Bb4',
          key: 85
        }, {
          url: 'B4',
          key: 74
        },
        {
          url: 'C5',
          key: 75
        }, {
          url: 'Db5',
          key: 79
        }, {
          url: 'D5',
          key: 76
        }, {
          url: 'Eb5',
          key: 80
        }, {
          url: 'E5',
          key: 186
        }
      ],
      listOne: [
        {
          url: 'C4'
        }, {
          url: 'Db4'
        }, {
          url: 'D4'
        }, {
          url: 'Eb4'
        }, {
          url: 'E4'
        }
      ],
      listTwo: [
        {
          url: 'F4'
        }, {
          url: 'Gb4'
        }, {
          url: 'G4'
        }, {
          url: 'Ab4'
        }, {
          url: 'A4'
        }, {
          url: 'Bb4'
        }, {
          url: 'B4'
        }
      ],
      listThree: [
        {
          url: 'C5'
        }, {
          url: 'Db5'
        }, {
          url: 'D5'
        }, {
          url: 'Eb5'
        }, {
          url: 'E5'
        }
      ],
      playingAudio: '#'
    }
  },
  methods: {
    plays (keys) {
      this.playPiano(keys)
    },
    playPiano (key) {
      // this.playingAudio = ''
      const url = require(`./audio/${key}.mp3`)
      // this.playingAudio = url
      // this.$refs.playAudio.load()
      const link = document.createElement('audio')
      link.style.display = 'none'
      link.src = url
      link.autoplay = 'autoplay'
      document.body.appendChild(link)
    }
  },
  mounted () {
    this.$keyBoard(this, 'playPiano', this.arr)
  }
}
</script>
<style lang="stylus">
*
  margin 0
  padding 0
  box-sizing border-box
.piano-bigwrap
  display flex
  justify-content center
  align-items center
  background: linear-gradient(#fff, #000);
  height: 100vh;
.piano-container,.piano {
  display: flex;
}
.piano button:nth-child(odd) {
  --width: 140px;
  width: var(--width);
  height: calc(var(--width) * 3);
  background-color: #fff;
  border: 1px solid #333;
}
.piano button:nth-child(even) {
  --width: 90px;
  width: var(--width);
  height: calc(var(--width) * 3);
  background-color: #000;
  margin-left: calc(var(--width) / -2);
  margin-right: calc(var(--width) / -2);
  z-index: 2;
}
</style>
